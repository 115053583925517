import type { CompVersions, GraphModelNode, JsVersions, NodeContext } from "../../types"
import { clearWarning, sendWarning } from "./warnings"

export const warnDepricated = (model: GraphModelNode, context: NodeContext, versions: CompVersions | JsVersions) => {
	const v = model.parameters.version
	const warn = context.graphModel.settings.warnDeprecated

	if (warn && v && versions[v].hashTag === '#deprecated')
		sendWarning(model, context, 'Deprecated', 'This version is deprecated')
	else clearWarning(model, context, 'Deprecated')
}
